<template>
  <div>
    <p class="title">订单列表</p>
    <div class="agent_search">
      <label class="lab" for="searchname">充值账号</label>
      <el-input clearable name="searchname" placeholder="请输入充值账号" class="goods_search" v-model="re_account"></el-input>
      <label class="lab" for="searchnum">商户号</label>
      <el-input clearable name="searchnum" placeholder="请输入商户号" class="goods_search" v-model="merchantnum"></el-input>
      <label class="lab" for="searchnum">商品编码</label>
      <el-input clearable name="searchnum" placeholder="请输入商品编码" class="goods_search" v-model="goods_id"
        @change="getgood"></el-input>
      <label class="type_lab" for="select">订单状态</label> <el-select v-model="recharge_state" @change="getorder(2, 1)"
        style="width:180px" clearable placeholder="请选择订单状态" name="select">
        <el-option v-for="item in txoptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option></el-select>
      <label class="type_lab" for="select">商品名称</label> <el-select v-model="goods_name" style="width:150px"
        @change="getorder(2, 1)" clearable placeholder="请选择商品名称" name="select">
        <el-option v-for="item in potion" :key="item.id" :label="item.good_name" :value="item.id">
        </el-option></el-select>

      <p class="searchtime">
        <span style="font-size:16px;margin-right:12px;">订单时间</span>
        <el-date-picker v-model="agent_time" type="datetimerange" @change="getorder(2, 1)"
          value-format="yyyy-MM-dd HH:mm:ss" :picker-options="a_pickerOptions" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" align="right">
        </el-date-picker>
      </p>
      <label class="lab" for="searchname">订单号</label>
      <el-input clearable name="searchname" placeholder="请输入订单号" style="width:250px" class="goods_search"
        v-model="order_num"></el-input>
      <Button type="primary" style="margin:0 15px 0 15px" @click="getorder(2, 1)">搜索</Button>
      <Button type="primary" @click="reset">重置</Button>
      <Button type="primary" style="margin:0 15px 0 15px" @click="refresh_order">刷新订单</Button>
      <Button type="primary" @click="getorder(1)">导出</Button>
    </div>

    <div style="text-align:left">
      <Button type="primary" style="margin:9px 5px 7px 10px" @click="getmesg">批量复制名称和账号</Button>
      <Button type="primary" style="margin:9px 5px 7px 10px" @click="getaccount">批量复制账号</Button>
      <Button type="primary" style="margin:9px 5px 7px 5px" @click="batchgoods">批量发货</Button>
      <Button type="primary" style="margin:9px 5px 7px 5px" @click="tobatchrob">批量领单</Button>
      <Button type="error" @click="end_refresh()" v-if="ifrefresh" style="margin:9px 0 7px 5px">关闭自动刷新</Button>
      <Button type="primary" style="margin:9px 5px 7px 5px" v-else @click="start_refresh">开启自动刷新</Button>
    </div>
    <el-table height="530" ref="multipleTable" :data="tableData" tooltip-effect="dark" border
      style="width: 99%;margin-left:10px" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column label="订单号" show-overflow-tooltip prop="order_no" align="center" width="200">
      </el-table-column>
      <el-table-column prop="create_time" label="充值时间" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="good_name" label="商品名称" width="130" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="recharge_account" label="充值账号" width="120" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="num" label="充值数量" width="80" align="center">
      </el-table-column>
      <el-table-column prop="money" label="应付金额" width="120" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="充值状态" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.state == 0" style="color:red">待充值</span>
          <span v-if="scope.row.state == 1" style="color:red">充值中</span>
          <span v-if="scope.row.state == 2">充值成功</span>
          <span v-if="scope.row.state == 3" style="color:red">充值失败</span>
          <span v-if="scope.row.state == 4">发货中</span>
          <span v-if="scope.row.state == 5">已领单</span>
        </template>
      </el-table-column>
      <el-table-column prop="supplier_str" align="center" label="商品归属" width="120">
      </el-table-column>
      <el-table-column prop="m_name" label="下单商户" width="120" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-dropdown trigger="click" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown" style="width: 130px !important;text-align: center;">
              <!-- <el-dropdown-item style="color: #3743D6;" v-if="scope.row.state==1" @click.native="deal_order(scope.row)">领单</el-dropdown-item> -->
              <el-dropdown-item style="color: #3743D6;" v-if="scope.row.state == 0 || scope.row.state == 1"
                @click.native="deal_complated(scope.row)">发货</el-dropdown-item>
              <el-dropdown-item style="color: #3743D6;" v-if="scope.row.state == 0 || scope.row.state == 1"
                @click.native="refundmoney(scope.row)">退款</el-dropdown-item>
              <el-dropdown-item @click.native="lookdetail(scope.row)" style="color: #3743D6;">订单详情</el-dropdown-item>
              <el-dropdown-item @click.native="com_details(scope.row)" style="color: #3743D6;" v-if="scope.row.supplier_id == 168">组合订单详情</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <p style="text-align:left;paddingLeft:10px;fontSize:16px" v-if="countdeta && this.goods_name && this.goods_id"><span
        style="color:red">成功订单：{{ count }}单</span>
      <span v-for="(item, index) in countdeta" :key="index">
        <span v-if="countdeta && index < 3" style="color:blue">&nbsp;{{ item.name }}：{{ item.num }}单；</span>
      </span>
      <el-link :underline="false" style="fontSize:16px;color:blue" v-if="countdeta.length > 3"
        @click="moredata = true">查看更多</el-link>
    </p>
    <el-pagination style="margin-top:5px" background prev-text='上一页' next-text='下一页'
      layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
      :current-page.sync="currentPage">
    </el-pagination>
    <el-dialog :visible.sync="usernamedialog" width="700px">
      <span slot="title" style="font-size: 16px;color: #000;">
        {{ copy_title }}
      </span>
      <el-input type="textarea" :autosize="{ minRows: 11, maxRows: 14 }" resize='none' v-model="batch_username">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="usernamedialog = false">取 消</el-button>
        <el-button type="primary" @click="copyaccount" :data-clipboard-text="batch_username" id="getuser">复制</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="orderdetail" width="925px">
      <div style="textAlign:left">
        <p class="titles">订单信息</p>
        <el-row :gutter="20" class="deta">
          <el-col :span="10">
            <p>订单提交时间: <span>{{ row_val.create_time }}</span></p>
            <p>订单号：<span>{{ row_val.order_no }}</span>
              <span class="copyacc" :data-clipboard-text="batch_username" @click="copy_account(row_val.order_no)"
                id="copyinfo2">复制</span>
            </p>
            <p>商品归属：<span>{{ row_val.supplier_str }}</span></p>
          </el-col>
          <el-col :span="14">
            <p>订单完成时间：<span v-if="row_val.success_time != '-'">{{ row_val.success_time }}</span>
              <span style="padding-left: 80px;">订单完成耗时：
                <span v-if="row_val.success_time">
                  <span v-if="days > 0">{{ days }}天</span>
                  <span v-if="hours > 0">{{ hours }}时</span>
                  <span v-if="minutes > 0">{{ minutes }}分</span>
                  <span v-if="second > 0">{{ second }}秒</span>
                </span></span>
            </p>
            <p>订单类型：{{ row_val.order_type }}</p>
            <p>下单商户：{{ row_val.m_name }}</p>
          </el-col>
        </el-row>
        <el-table :data='tabData' style="width: 100%">
          <el-table-column prop="good_name" label="商品信息">
          </el-table-column>
          <el-table-column label="充值账号">
            <template slot-scope="scope">
              <div>{{ scope.row.recharge_account }}
                <span class="copyacc" :data-clipboard-text="batch_username"
                  @click="copy_account(scope.row.recharge_account)" id="copyinfo2">复制</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="充值数量">
          </el-table-column>
          <el-table-column prop="money" label="应付金额">
          </el-table-column>
          <el-table-column prop="supplier_str" label="充值状态">
          </el-table-column>
          <el-table-column prop="recharge_error_message" label="失败原因">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="查看更多" :visible.sync="moredata" width="30%">
      <p style="fontSize:16px;color:blue">成功订单：{{ count }}单；</p>
      <p v-for="(item, index) in countdeta" :key="index">
        <span style="fontSize:16px;color:blue">{{ item.name }}：{{ item.num }}单；</span>
      </p>
    </el-dialog>




    <el-dialog title="组合订单明细" :visible.sync="comDetail" width="980px">
      <el-table :data="tableDatasCom" style="width: 100%">
        <el-table-column align="center" prop="good_name" label="子商品名称" >
        </el-table-column>
        <el-table-column align="center" prop="order_no" label="订单号" width="160"></el-table-column>
        <el-table-column align="center" prop="coding" label="商品编码" width="80"> </el-table-column>
        <el-table-column align="center" prop="purchase_price" width="90" label="进价"> </el-table-column>
        <el-table-column align="center" prop="s_name" width="90" label="商品归属">
    
        </el-table-column>
        <el-table-column align="center" prop="state" width="90" label="充值状态">
          <template slot-scope="scope">
            {{  scope.row.state == 1 ? '充值成功' : scope.row.state == 2 ? '充值失败' : '充值中'  }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="create_time" width="150" label="充值时间">
        </el-table-column>
        <el-table-column align="center" prop="error_msg" width="120" label="失败原因"> </el-table-column>
      </el-table>
    </el-dialog>







  </div>
</template>

<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      days: "",
      hours: "",
      second: "",
      minutes: "",
      r_account: false,
      moredata: false,
      countdeta: [],
      tabData: [
        {
          good_name: "",
          recharge_account: "",
          num: "",
          money: "",
          supplier_str: ""
        }
      ],
      row_val: "",
      orderdetail: false,
      count: [],
      refresh: "",
      ifrefresh: false,
      total: 0,
      pagesize: 10,
      currentPage: 1,
      tableData: [],
      checked: [],
      potion: [],
      order_num: "",
      goods_name: "",
      agent_time: [],
      a_pickerOptions: {
        shortcuts: [
          {
            text: '二十四小时内',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      usernamedialog: false,
      copy_title: '',
      batch_username: '',
      merchantnum: "",
      re_account: "",
      goods_id: "",
      recharge_state: "",
      txoptions: [{
        value: '0',
        label: '待充值'
      }, {
        value: '1',
        label: '充值中'
      }, {
        value: '2',
        label: '充值成功'
      }, {
        value: '3',
        label: '充值失败'
      }, {
        value: '4',
        label: '发货中'
      }, {
        value: '5',
        label: '已领单'
      },
      ],
      tableDatasCom: [],
      comDetail:false,
    }
  },
  destroyed() {
    clearInterval(this.refresh)
    this.ifrefresh = false
  },
  mounted() {
    this.chanlist('2-1', '2')
    this.settime()
    this.getorder(2)
  },
  methods: {



    com_details(row) {
      this.comDetail = true
      // http://fictitious.chengmeiyoupin.com/full/order/info?order_no=订单号
      console.log(row, "rowrowrowrowrow");
      // return
      let api = `/supplier/order/combination.list`;
      // let params = { order_sn: row.orderNum, type: 1 };
      let params = { id:row.id };

      console.log(this.tableDatasCom, "tableDatasComtableDatasCom");


      this.$axios.post(api,params).then(res => {
        if (res.data.code == 20000) {
          this.tableDatasCom = res.data.data;
        } else {
          this.$message({
            message: res.data.message,
            type: "warning"
          })
        }
      })

      return



    },












    refundmoney(row) {
      this.$confirm('您确定要退款吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: 'true',
      }).then(() => {
        this.$axios.post("/supplier/order/refund", { id: row.id }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              message: res.data.message,
              type: "success"
            })
            this.getorder(2, 1)
          } else {
            this.$message({
              message: res.data.message,
              type: "warning"
            })
          }
        })
      })
    },
    //发货
    deal_complated(row) {
      this.$confirm('您确定该订单已处理完成了吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: 'true',
      }).then(() => {
        this.$axios.post("/supplier/order/ship", { ids: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              message: "操作成功",
              type: "success"
            })
            this.getorder(2, 1)
          } else {
            this.$message({
              message: "操作失败",
              type: "warning"
            })
          }
        })
      })
    },
    //领单
    deal_order(row) {
      this.$confirm('您确定领取该订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: 'true',
      }).then(() => {
        this.$axios.post("/supplier/order/receipt", { ids: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              message: "操作成功",
              type: "success"
            })
            this.getorder(2, 1)
          } else {
            this.$message({
              message: "操作失败",
              type: "warning"
            })
          }
        })
      })
    },
    //查看详情
    lookdetail(row) {
      this.row_val = row
      this.tabData = [{
        good_name: row.good_name,
        recharge_account: row.recharge_account,
        num: row.num,
        money: row.money,
        supplier_str: row.state_str
      }]
      var s_data = new Date(this.row_val.create_time)
      var e_data = new Date(this.row_val.success_time)
      let datediff = e_data.getTime() - s_data.getTime()
      // 计算相差的天数
      const days = Math.floor(datediff / (24 * 3600 * 1000))
      // 计算天数后剩余的毫秒数
      const leave1 = datediff % (24 * 3600 * 1000)
      // 计算出小时数
      const hours = Math.floor(leave1 / (3600 * 1000))
      // 计算小时数后剩余的毫秒数
      const leave2 = leave1 % (3600 * 1000)
      // 计算相差分钟数
      const minutes = Math.floor(leave2 / (60 * 1000))
      // 计算分钟数后剩余的毫秒数
      const leave3 = leave2 % (60 * 1000)
      // 计算相差秒数
      const second = Math.floor(leave3 / 1000)
      this.days = days
      this.hours = hours
      this.minutes = minutes
      this.second = second
      this.orderdetail = true
    },
    //刷新
    getorders() {
      this.settime()
      var time;
      if (this.agent_time == null) {
        time = ''
      } else {
        time = this.agent_time[0] + "," + this.agent_time[1]
      }
      this.$axios.post("/supplier/order/list",
        {
          page: this.currentPage, limit: this.pagesize, recharge_account: this.re_account, m_account: this.merchantnum, gid: this.goods_name,
          order_no: this.order_num,
          state: this.recharge_state, time: time
        })
        .then((res) => {
          if (res.data.code == 20000) {
            this.tableData = res.data.data.data.rows
            this.total = res.data.data.data.total
            this.count = res.data.data.count.total
            this.countdeta = res.data.data.count.rows
            this.$message({
              message: "订单已刷新",
              type: "success"
            })
          } else {
            this.tableData = []
            this.total = 0
            this.count = 0
            this.countdeta = []
          }
        })
    },
    //开启自动刷新
    start_refresh() {
      this.getorders()
      this.refresh = setInterval(this.getorders, 10000)
      this.ifrefresh = true
    },
    //关闭
    end_refresh() {
      clearInterval(this.refresh)
      this.ifrefresh = false
    },
    //批量领单
    tobatchrob() {
      if (this.checked.length < 1) {
        this.$message({
          message: '请勾选订单',
          type: 'error',
          center: 'true',
        })
        return
      }
      this.$confirm('您确定要批量领单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: 'true',
      }).then(() => {
        let orderid = []
        let orderstatu = []
        for (let i in this.checked) {
          orderid.push(this.checked[i].id)
          orderstatu.push(this.checked[i].state_str)
        }
        orderid = orderid.join()
        if (orderstatu.indexOf('充值成功') > -1 || orderstatu.indexOf('充值失败') > -1) {
          this.$message({
            message: "充值失败和充值成功的订单无法领单",
            type: 'error'
          })
        } else {
          this.$axios.post("/supplier/order/receipt", { ids: orderid }).then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
            } else {
              this.$message({
                message: "操作失败",
                type: 'warning'
              })
            }
          })
        }
      })
    },
    //批量发货
    batchgoods() {
      if (this.checked.length < 1) {
        this.$message({
          message: '请勾选订单',
          type: 'error',
          center: 'true',
        })
        return
      }
      this.$confirm('您确定要批量发货吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: 'true',
      }).then(() => {
        let orderid = []
        let orderstatu = []
        for (let i in this.checked) {
          orderid.push(this.checked[i].id)
          orderstatu.push(this.checked[i].state_str)
        }
        orderid = orderid.join()
        if (orderstatu.indexOf('充值成功') > -1 || orderstatu.indexOf('充值失败') > -1) {
          this.$message({
            message: "充值失败和充值成功的订单无法发货货",
            type: 'error'
          })
        } else {
          this.$axios.post("/supplier/order/ship", { ids: orderid }).then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
            } else {
              this.$message({
                message: "操作失败",
                type: 'warning'
              })
            }
          })
        }

      })
    },
    getgood() {
      if (this.goods_id == '') {
        return false
      }
      this.$axios.post('/supplier/good/nameById', { ids: this.goods_id }).then((res) => {
        if (res.data.code == 20000) {
          this.potion = res.data.data
        } else {
          this.potion = []
        }
      })
    },
    copy_account(info) {
      this.batch_username = info
      var clipboard = new this.Clipboard(".copyacc"); //在main.js中引用
      clipboard.on("success", () => {
        // 释放内存
        this.$message({
          message: '复制成功！',
          type: 'success',
          center: 'true',
        });
        this.batch_username = '';
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持复制！',
          type: 'error',
          center: 'true',
        });
        // 释放内存
        clipboard.destroy();
      });
    },
    copyaccount() {
      var clipboard = new this.Clipboard("#getuser"); //在main.js中引用
      clipboard.on("success", () => {
        // 释放内存
        this.$message({
          message: '复制成功！',
          type: 'success',
          center: 'true',
        });
        this.usernamedialog = false;
        this.batch_username = '';
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持复制！',
          type: 'error',
          center: 'true',
        });
        // 释放内存
        clipboard.destroy();
      });
    },
    //复制账号
    getaccount() {
      if (this.checked.length < 1) {
        this.$message({
          message: '请勾选订单',
          type: 'error',
          center: 'true',
        })
        return false
      }
      var username = []
      for (let i in this.checked) {
        username.push(this.checked[i].recharge_account)
      }
      username = username.join('\r\n')
      // username = username.replace(/[,，]/g,"\r\n")
      this.batch_username = username;
      this.copy_title = "批量复制账号"
      this.usernamedialog = true;

    },
    //复制账号名称
    getmesg() {
      if (this.checked.length < 1) {
        this.$message({
          message: '请勾选订单',
          type: 'error',
          center: 'true',
        })
        return false
      }
      var username = []
      for (let i in this.checked) {
        username.push(this.checked[i].good_name + "            " + this.checked[i].recharge_account)
      }
      username = username.join('\r\n')
      // username = username.replace(/[,，]/g,"\r\n")
      this.batch_username = username;
      this.copy_title = "批量复制名称和账号"
      this.usernamedialog = true;
    },
    //刷新订单
    refresh_order() {
      console.log("刷新订单");
      this.currentPage = 1
      this.settime();
      this.getorder(2, 1);//供应商订单
      this.$message({
        message: "订单已刷新",
        type: "success"
      })
    },
    settime() { this.agent_time = [this.formatDate(new Date(new Date(new Date()).getTime() - 3600 * 1000)), this.formatDate(new Date())]; },
    formatDate(now) {
      let year = now.getFullYear();  //取得4位数的年份
      let month = now.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
      let date = now.getDate();      //返回日期月份中的天数（1到31）
      let hour = now.getHours();     //返回日期中的小时数（0到23）
      let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
      let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
      month < 10 ? month = '0' + month : month;
      date < 10 ? date = '0' + date : date;
      hour < 10 ? hour = '0' + hour : hour;
      minute < 10 ? minute = '0' + minute : minute;
      seconds < 10 ? seconds = '0' + seconds : seconds;
      return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + seconds;
    },
    //重置
    reset() {
      this.currentPage = 1
      this.goods_id = ''
      this.goods_name = ''
      this.re_account = ''
      this.merchantnum = ''
      this.order_num = ''
      this.recharge_state = ''
      this.settime()
      this.getorder(2)
    },
    current_change(page) {
      this.currentPage = page;
      this.getorder(2)
    },
    handleSelectionChange(val) {
      this.checked = val;
    },
    getorder(exp, pag) {
      if (pag == 1) {
        this.currentPage = 1
      }
      var time;
      if (this.agent_time == null) {
        time = ''
      } else {
        time = this.agent_time[0] + "," + this.agent_time[1]
      }
      this.$axios.post("/supplier/order/list",
        {
          page: this.currentPage, limit: this.pagesize, recharge_account: this.re_account, m_account: this.merchantnum, gid: this.goods_name,
          order_no: this.order_num,
          state: this.recharge_state, time: time, export: exp
        })
        .then((res) => {
          if (res.data.code == 20000) {
            if (exp == 1) {
              window.open(res.data.data, '_blank')
            } else {
              this.tableData = res.data.data.data.rows
              this.total = res.data.data.data.total
              this.count = res.data.data.count.total
              this.countdeta = res.data.data.count.rows
            }
          } else {
            this.tableData = []
            this.total = 0
            this.count = ''
          }
        })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 20px;
}

.titles {
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 18px;
  color: blue;
}

.agent_search {
  height: 130px;
  border: 1px solid #ccc;
  margin-top: 10px;
  text-align: left;
  margin-left: 10px;
}

.goods_search {
  width: 150px;
  height: 23px;
  border-color: initial;
  margin-left: 3px;
  display: inline-block;
}

.type_lab {
  height: 34px;
  width: 90px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
}

.lab {
  font-size: 16px;
  margin: 20px 10px 0 20px;
  display: inline-block
}

.searchtime {
  width: 500px;
  margin: 10px 18px 10px 16px;
  display: inline-block
}

.deta {
  padding-top: 10px;

  p {
    height: 30px;
    padding-left: 10px;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.copyacc {
  color: blue;
  padding-left: 5px;
  cursor: pointer;
}
</style>